module.exports = {
  ...require('./src/arrays/arrays'),
  ...require('./src/compose/compose'),
  ...require('./src/copy/copy'),
  ...require('./src/types/either'),
  ...require('./src/group/group'),
  ...require('./src/match/match'),
  ...require('./src/types/maybe'),
  ...require('./src/predicates/predicates'),
  ...require('./src/reducers/reducers'),
 };
