import PropTypes from 'prop-types';
import React from 'react';

import DefaultShoeImage from '../img/90sbox.jpg';

class ItemInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fulfillments: this.props.item.fulfillments,
      gtin: this.props.item.gtin,
      imageUrls: this.props.item.itemDetails.imageUrls,
      name: this.props.item.itemDetails.name,
      quantityRequested: this.props.item.quantityRequested,
      size: this.props.item.itemDetails.size,
      status: this.props.item.status,
      styleColor: `${this.props.item.itemDetails.style}-${this.props.item.itemDetails.color}`,
    };
  }

  render = () => (
    <div className="item-details">
      <div className="item-info">
        <div className="order-image">
          <img alt="item" src={this.state?.imageUrls?.[0] || DefaultShoeImage} />
        </div>
        <div className="item-identifiers">
          <div className="name"><b>Name:</b> {this.state.name}</div>
          <div className="gtin"><b>GTIN:</b> {this.state.gtin}</div>
          <div className="style-color"><b>Style-Color:</b> {this.state.styleColor}</div>
          <div className="size"><b>Size:</b> {this.state.size}</div>
          <div className="quantity-requested"><b>Quantity Requested:</b> {this.state.quantityRequested}</div>
        </div>
      </div>
      <div className="item-status">
        <div className="status"><b>Item Status:</b> {this.state.status}</div>
        <div className="fulfillments"><b>Fulfillments:</b>
          {this.state.fulfillments.map((fulfillment) => (
            <div key={fulfillment.status} className="fulfillment">
              <div>Status: {fulfillment.status}</div>
              {fulfillment.reason && <div>Reason: {fulfillment.reason}</div>}
              <div>Qty: {fulfillment.quantity}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

ItemInfo.defaultProps = {
  item: {
    fulfillments: [],
    gtin: 'gtin',
    itemDetails: {
      color: 'color',
      imageUrls: [],
      name: 'name',
      size: 'size',
      style: 'style',
    },
    quantityRequested: 0,
    status: 'status',
  },
};

ItemInfo.propTypes = {
  item: PropTypes.shape({
    fulfillments: PropTypes.arrayOf(PropTypes.shape({
      quantity: PropTypes.number,
      status: PropTypes.string,
    })),
    gtin: PropTypes.string,
    itemDetails: PropTypes.shape({
      color: PropTypes.string,
      imageUrls: PropTypes.arrayOf(PropTypes.string),
      name: PropTypes.string,
      size: PropTypes.string,
      style: PropTypes.string,
    }),
    quantityRequested: PropTypes.number,
    status: PropTypes.string,
  }),
};

export default ItemInfo;
