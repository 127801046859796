import qs from 'qs';
import request from 'request-promise';
import urlJoin from 'url-join';

import { MSG_AUTH_EXPIRE } from '../../constants/constants';
import { isCurrent } from '../local-storage';

const config = require('../../config')();

export const oktaAuth = (currentRoute = '/home') => {
  const queryString = qs.stringify({
    client_id: config.client_id,
    nonce: 'NONCE_STRING',
    redirect_uri: config.redirectUrl,
    response_type: config.response_type,
    scope: config.scope,
    state: currentRoute,
  });
  window.location.href = urlJoin(config.authUrl, `?${queryString}`);
};

export const storeViewsByCountry = (country, published, fields) => {
  const options = {
    headers: {
      'nike-api-caller-id': 'com.nike:test-caller-id',
    },
    json: true,
    resolveWithFullResponse: true,
    url: `${config.storeviewsHost}${config.storeviewsResource}?search=(address.country==${country} and (company!=Hurley) and published==${published})&count=3000${fields ? `&fields=${fields.join(',')}` : ''}`,
  };
  return request.get(options);
};

export const requestWrapper = async (token, resource, method, queryStringParameters, body) => {
  if (isCurrent()) {
    const options = {
      body,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      json: true,
      qs: queryStringParameters,
      resolveWithFullResponse: true,
      uri: config.rosHost + resource,
    };
    return request[method](options);
  }
  throw new Error(MSG_AUTH_EXPIRE);
};

export const emailFeedback = (token, body) => requestWrapper(
  token,
  config.emailResource,
  'post',
  undefined,
  body,
);
