import PropTypes from 'prop-types';
import React from 'react';

import ItemInfo from './ItemInfo';
import OrderInfoModal from './OrderInfoModal';

class OrderInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doms: this.props.doms,
      fmg: this.props.fmg,
      fulfillmentReferenceNumber: this.props.fulfillmentReferenceNumber,
      ofr: this.props.ofr,
      openModal: false,
      orderJSON: {},
      orderNumber: this.props.orderNumber,
      task: this.props.task,
    };
  }

  loadOrderJSON(event) {
    const { value } = event.target;
    const newState = { openModal: true, orderJSON: this.state[value] };
    this.setState(newState);
  }

  // eslint-disable-next-line class-methods-use-this
  closeModal(that) {
    that.setState({ openModal: false });
  }

  render = () => (
    <div className="order-info">
      <div className="order-header">
        <div className="order-identifiers">
          <div className="order-number">Order Number: {this.state.orderNumber}</div>
          <div className="frn">Fulfillment Reference Number: {this.state.fulfillmentReferenceNumber}</div>
        </div>
        <div className="order-status-group">
          <div className="order-status">Order Status: {this.state.ofr.status}</div>
          <div className="order-dates">Creation Date: {this.state.task.taskOrigin.orderCreationDate} | Modification Date: {this.state.task.modificationDate}</div>
          <div className="order-fulfillment-dates">Expected Fulfillment Date: {this.state.task.taskOrigin.expectedFulfillmentDate} | EDD: {this.state.task.taskOrigin.estimatedDeliveryDate}</div>
        </div>
      </div>
      <div className="items">
        <b>Items:</b>
        {this.state.task.items.map((item) => (
          <ItemInfo key={item.gtin} item={item} />
        ))}
      </div>
      <div className="order-actions">
        <div className="json-buttons">
          <button type="button" value="task" onClick={(event) => this.loadOrderJSON(event)}>Tasks</button>
          <button type="button" value="ofr" onClick={(event) => this.loadOrderJSON(event)}>OFR</button>
          <button type="button" value="fmg" onClick={(event) => this.loadOrderJSON(event)}>FMG</button>
          <button type="button" value="doms" onClick={(event) => this.loadOrderJSON(event)}>DOMS</button>
        </div>
        {this.state.task.cartons?.[0].trackingNumber && <div className="tracking-number">📦 Tracking: {this.state.task.cartons[0].trackingNumber}</div>}
      </div>
      <OrderInfoModal closeModal={() => this.closeModal(this)} isOpen={this.state.openModal} orderJSON={this.state.orderJSON} />
    </div>
  );
}

OrderInfo.defaultProps = {
  doms: undefined,
  fmg: undefined,
  fulfillmentReferenceNumber: undefined,
  ofr: undefined,
  orderNumber: undefined,
  task: undefined,
};

OrderInfo.propTypes = {
  doms: PropTypes.shape({}),
  fmg: PropTypes.shape({}),
  fulfillmentReferenceNumber: PropTypes.string,
  ofr: PropTypes.shape({}),
  orderNumber: PropTypes.string,
  task: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default OrderInfo;
