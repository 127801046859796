import { Input } from '@nike/frame-component-library';
import PropTypes from 'prop-types';
import React from 'react';

import { formatStoreAlias } from '../../utils/formatting';
import toGeo from '../../utils/static/scs-geo-iso-map';
import { REQUIRED_FIELD } from '../../utils/validation/input-validation';
import { ButtonBlack, ButtonSubmit, Select } from '../reusable';

const GEO = 'GEO';
const COUNTRY = 'COUNTRY';
const GLOBAL = 'GLOBAL';
const STORE = 'STORE';
class Keyset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      scopeType: this.props.currentPair.scopeType,
      value: this.props.currentPair.value,
    };
  }

  onInputChange = (value, prop = 'value') => (value.target && (value.target.value || value.target.value === '')
    ? this.setState({ [prop]: value.target.value })
    : this.setState({ [prop]: value }));

  onSave = () => {
    if (this.saveDisabled()) {
      return;
    }
    const toSend = {
      body: {
        hierarchy: {
          brand: this.props.currentStore.brand,
          country: this.props.currentStore.address.country,
          geo: toGeo(this.props.currentStore),
        },
        key: this.props.name,
        scope: '',
        scopeType: this.state.scopeType,
        value: this.state.value,
      },
      keyType: this.props.type?.toUpperCase() || '',
    };
    // set up scope differently depending on scopeType
    switch (this.state.scopeType) {
      case (STORE):
        toSend.body.scope = formatStoreAlias(this.props.currentStore);
        break;
      case (COUNTRY):
        toSend.body.scope = this.props.currentStore.address.country;
        break;
      case (GEO):
        toSend.body.scope = toGeo(this.props.currentStore);
        delete toSend.body.hierarchy;
        break;
      case (GLOBAL):
        toSend.body.scope = GLOBAL;
        delete toSend.body.hierarchy;
        break;
      default:
        return;
    }
    this.props.onSave(toSend);
  }

  saveDisabled = () => (this.state.value === 'Undefined' || this.state.scopeType === 'Undefined');

  toggleBool = (prop) => this.setState((prevState) => ({ [prop]: !prevState[prop] }));

  render = () => (
    <article className="border bg-white mb2-sm p2-sm ta-sm-l">
      <div className="mb2-sm u-cursor-pointer" role="menuitem" tabIndex={0}>
        <span>{this.props.name} | {this.props.currentPair.value.toString().replace(/,/g, ', ').replace(/websurvey/g, 'websurvey ')}</span>
        <ButtonBlack className="fl-sm-r" isDisabled={this.props.isReadOnly} label="Edit" onClick={() => this.toggleBool('editing')} />
      </div>
      {this.state.editing && (
        <>
          <section className="ncss-row">
            {this.props.description && <p className="ncss-col-sm-12 body-2">KEY DESCRIPTION: {this.props.description}</p>}
            <p className="ncss-col-sm-10 va-sm-t body-2">This store&#39;s value is inherited from the {this.props.currentPair.scopeType} scope.</p>
          </section>
          <section className="ncss-row mt2-sm">
            <Select
              className="ncss-col-sm-12 ncss-col-md-6 va-sm-t"
              errorMessage={this.state.scopeType === 'Undefined' ? REQUIRED_FIELD : ''}
              id="scopeType"
              label="Scope Type"
              options={[GEO, COUNTRY, STORE, GLOBAL].map((value) => ({ label: value, value }))}
              value={this.state.scopeType}
              onChange={(value) => this.onInputChange(value, 'scopeType')}
            />
            {this.props.type === 'boolean'
              ? (
                <Select
                  className="ncss-col-sm-12 ncss-col-md-6 va-sm-t"
                  errorMessage={this.state.value === 'Undefined' ? REQUIRED_FIELD : ''}
                  id="value"
                  label="Value"
                  options={['true', 'false'].map((value) => ({ label: value, value }))}
                  value={this.state.value}
                  onChange={this.onInputChange}
                />
              )
              : (
                <article className="ncss-col-sm-12 ncss-col-md-6 va-sm-t">
                  <Input
                    errorMessage={this.state.value === 'Undefined' ? REQUIRED_FIELD : ''}
                    label="Value"
                    style={{ zIndex: 1 }}
                    type={(this.props.type === 'integer') ? 'number' : 'text'}
                    value={this.state.value === 'Undefined' ? '' : this.state.value}
                    onChange={this.onInputChange}
                  />
                </article>
              )}
            <ButtonSubmit className="ncss-col-sm-12 mt-8-sm ta-sm-c" isDisabled={this.saveDisabled()} label="Save" onClick={this.onSave} />
          </section>
        </>
      )}
    </article>
  );
}

Keyset.defaultProps = {
  currentPair: {
    scopeType: 'Undefined',
    value: 'Undefined',
  },
};

Keyset.propTypes = {
  currentPair: PropTypes.shape({
    scopeType: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  }),
  currentStore: PropTypes.shape({
    address: PropTypes.shape({
      country: PropTypes.string,
    }),
    brand: PropTypes.string,
    storeNumber: PropTypes.string,
  }).isRequired,
  description: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default Keyset;
