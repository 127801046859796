export const rosPermissions = 'Users';

export const WaitlinesPermissions = 'Waitlines';
export const o2oPermissions = 'O2O';
export const patchPermissions = 'Patch';
export const posReasonPermissions = 'POSReasons';
export const printerPortalPermissions = 'PrinterManagement';
export const readOnlyPermissions = 'ReadOnly';
export const registerLevelTrackingPermissions = 'RLT';
export const registerLevelTrackingPermissionsReadOnly = 'RLT.ReadOnly';
export const scsPermissions = 'SCS';
export const slsPermissions = 'SLS';
export const storeServicesPermissions = 'Zones';
export const trafficPermissions = 'Traffic';
export const zonesPermissions = 'Zones';
export const taxIdentifierPermissions = 'TaxIdentifier';
export const ccmPermissions = 'CCM';

/*
 * This is a sample user configuration so we can fake OKTA logins when running locally.
 * By commenting out a member of the array, you can effectively "remove" yourself from that group.
 *
 * PLEASE do NOT change the values below unless you know what you are doing. Thanks.
 */
const frameUser = [
  'App.RetailOperations.Users',
  'Application.RISE.Operations.Users.DevicesAndWaitlines',
  'App.RetailOperations.Users.O2OCapabilities',
  'App.RCF.ROS.Users.POSReasonsAdmin.CRUD',
  'Application.RISE.Operations.Users.Patch',
  'Application.RISE.Operations.Users.PrinterManagement',
  /* do not be a member of read only unless you want to have read only access to scs */
  // 'Application.RISE.Operations.Users.ReadOnly',
  'App.RetailOperations.Users.RLT',
  // 'App.RetailOperations.Users.RLT.ReadOnly',
  'Application.RISE.Operations.Users.SCS',
  'Application.RISE.Operations.Users.SCS.ALL',
  /* these other SCS groups are redundant if you have SCS.ALL */
  // 'Application.RISE.Operations.Users.SCS.ASSIST',
  // 'Application.RISE.Operations.Users.SCS.LINK',
  // Application.RISE.Operations.Users.SCS.RL,
  // 'Application.RISE.Operations.Users.SCS.RISE',
  // 'Application.RISE.Operations.Users.SCS.SFS',
  // 'Application.RISE.Operations.Users.SCS.SIM',
  'Application.RISE.Operations.Users.Traffic',
  'App.RetailOperations.Users.TaxIdentifiers',
  'Application.RISE.Operations.Users.Zones',
  'Application.RISE.AdminUI.Users',
  'Application.RISE.AdminUI.Owner',
  'App.RISE.AdminUI.Users.SLS.ReadOnly',
  'Application.RISE.AdminUI.Users.SLS.ALL',
  'Application.RISE.AdminUI.Users.SLS.IslandPacific',
  /* currently commented out because the Just Enough section has a bug in it */
  // 'Application.RISE.AdminUI.Users.SLS.JustEnough',
  /* these other SLS region groups are redundant if you have SLS.ALL */
  // 'Application.RISE.AdminUI.Users.SLS.AMER',
  // 'Application.RISE.AdminUI.Users.SLS.APLA',
  // 'Application.RISE.AdminUI.Users.SLS.EMEA',
  // 'Application.RISE.AdminUI.Users.SLS.GC',
  // 'Application.RISE.AdminUI.Users.SLS.NA',
  'App.RBH.AdminUI.Users.SLS.CMP',
  'App.RetailOperations.CCM.Users.Admin',
  'App.RetailOperations.CCM.Users',
];
export const permissionsToGroups = {
  AdminO2O: 'App.RetailOperations.SwooshQR.O2OCapabilities.Admin',

  CCM: 'App.RetailOperations.CCM.Users',
  'CCM.ADMIN': 'App.RetailOperations.CCM.Users.Admin',
  O2O: 'App.RetailOperations.Users.O2OCapabilities',
  Patch: 'Application.RISE.Operations.Users.Patch',
  POSReasons: 'App.RCF.ROS.Users.POSReasonsAdmin.CRUD',
  PrinterManagement: 'Application.RISE.Operations.Users.PrinterManagement',
  ReadOnly: 'Application.RISE.Operations.Users.ReadOnly',
  RLT: 'App.RetailOperations.Users.RLT',
  'RLT.ReadOnly': 'App.RetailOperations.Users.RLT.ReadOnly',
  SCS: 'Application.RISE.Operations.Users.SCS',
  'SCS.ALL': 'Application.RISE.Operations.Users.SCS.ALL',
  'SCS.ASSIST': 'Application.RISE.Operations.Users.SCS.ASSIST',
  'SCS.LINK': 'Application.RISE.Operations.Users.SCS.LINK',
  'SCS.NPOS': 'App.RISE.Operations.Users.SCS.NPOS',
  'SCS.OTHER': 'App.RetailOperations.Users.SCS.OTHER',
  'SCS.POS': 'App.RetailOperations.Users.SCS.1POS',
  'SCS.PROMOTE': 'App.RetailOperations.Users.SCS.PromoteApp',
  'SCS.RISE': 'Application.RISE.Operations.Users.SCS.RISE',
  'SCS.RL': 'Application.RISE.Operations.Users.SCS.RL',
  'SCS.SCO': 'App.RISE.Operations.Users.SCS.SCO',
  'SCS.SFS': 'Application.RISE.Operations.Users.SCS.SFS',
  'SCS.SIM': 'Application.RISE.Operations.Users.SCS.SIM',

  SLS: 'Application.RISE.AdminUI.Users',
  'SLS.ALL': 'Application.RISE.AdminUI.Users.SLS.ALL',
  'SLS.AMER': 'Application.RISE.AdminUI.Users.SLS.AMER',
  'SLS.APLA': 'Application.RISE.AdminUI.Users.SLS.APLA',
  'SLS.ASIA_PACIFIC_LATIN_AMERICA': 'Application.RISE.AdminUI.Users.SLS.APLA',
  'SLS.EMERGING_MARKETS': 'Application.RISE.AdminUI.Users.SLS.AMER',
  'SLS.EU': 'Application.RISE.AdminUI.Users.SLS.EMEA',
  'SLS.EUROPE_MIDDLE_EAST_AFRICA': 'Application.RISE.AdminUI.Users.SLS.EMEA',
  'SLS.GC': 'Application.RISE.AdminUI.Users.SLS.GC',
  'SLS.GREATER_CHINA': 'Application.RISE.AdminUI.Users.SLS.GC',
  'SLS.IP': 'Application.RISE.AdminUI.Users.SLS.IslandPacific',
  'SLS.JE': 'Application.RISE.AdminUI.Users.SLS.JustEnough',
  'SLS.NA': 'Application.RISE.AdminUI.Users.SLS.NA',

  'SLS.NORTH_AMERICA': 'Application.RISE.AdminUI.Users.SLS.NA',
  'SLS.Owner': 'Application.RISE.AdminUI.Owner',
  'SLS.ReadOnly': 'App.RISE.AdminUI.Users.SLS.ReadOnly',

  TaxIdentifier: 'App.RetailOperations.Users.TaxIdentifiers',
  Traffic: 'Application.RISE.Operations.Users.Traffic',
  Users: 'App.RetailOperations.Users',
  Waitlines: 'Application.RISE.Operations.Users.DevicesAndWaitlines',
  Zones: 'Application.RISE.Operations.Users.Zones',
};

export const subGroupUsages = {
  'App.RetailOperations.Users.SCS.1POS': "View and edit POS team's SCS keys",
  'App.RetailOperations.Users.SCS.OTHER': 'View and edit other SCS keys',
  'App.RetailOperations.Users.SCS.PromoteApp': "View and edit Promote App's SCS keys",
  'App.RISE.Operations.Users.SCS.NPOS': "View and edit NPOS team's SCS keys",
  'App.RISE.Operations.Users.SCS.SCO': "View and edit Self Check out team's SCS keys",
  'Application.RISE.AdminUI.Users.SLS.ALL': 'Add/edit stores in all regions',
  'Application.RISE.AdminUI.Users.SLS.AMER': 'Add/edit stores in the AMER region',
  'Application.RISE.AdminUI.Users.SLS.APLA': 'Add/edit stores in the APLA region',
  'Application.RISE.AdminUI.Users.SLS.EMEA': 'Add/edit stores in the EMEA region',
  'Application.RISE.AdminUI.Users.SLS.GC': 'Add/edit stores in the GC region',
  'Application.RISE.AdminUI.Users.SLS.NA': 'Add/edit stores in the NA region',
  'Application.RISE.Operations.Users.SCS.ALL': 'View and edit all SCS keys',
  'Application.RISE.Operations.Users.SCS.ASSIST': "View and edit AIC, ASSIST, and NSP team's SCS keys",
  'Application.RISE.Operations.Users.SCS.LINK': "View and edit Nike+ LINK team's SCS keys",
  'Application.RISE.Operations.Users.SCS.RISE': "View and edit RISE team's SCS keys",
  'Application.RISE.Operations.Users.SCS.RL': "View and edit RISE team's RL keys",
  'Application.RISE.Operations.Users.SCS.SFS': "View and edit SFS team's SCS keys",
  'Application.RISE.Operations.Users.SCS.SIM': "View and edit SIM team's SCS keys",
};

export const doNotShowTheseGroups = [
  'Application.RISE.AdminUI.Owner',
  'Application.RISE.AdminUI.Users.SLS.JustEnough',
  'Application.RISE.AdminUI.Users.SLS.IslandPacific',
  'App.RetailOperations.Users.RLT.ReadOnly',
];

export const readOnlyAlternativeGroups = {
  'App.RetailOperations.Users.RLT': 'App.RetailOperations.Users.RLT.ReadOnly',
};

export const getReadOnlyAlternativeGroups = (auth) => readOnlyAlternativeGroups[permissionsToGroups[auth]];

const groupsToPermissions = Object.entries(permissionsToGroups).reduce((acc, [key, value]) => ({ ...acc, [value]: acc[value] ? acc[value].concat(key) : [key] }), {});

// making the array out of a spread Set from the array just removes duplicates, otherwise this is just finding all groups that start with your group name + '.'
export const getSubGroups = (permission) => [...new Set(Object.entries(permissionsToGroups).filter(([key]) => key.startsWith(`${permission}.`)).map(([, group]) => group))];

export const getUserPermissions = (userGroups) => {
  let currentUserReq;
  const userPermissions = [];

  // get the temp user if we're in dev mode
  if (process.env.NODE_ENV === 'development') {
    currentUserReq = frameUser;
  } else {
    currentUserReq = userGroups;
  }

  // add to body object for each group
  currentUserReq.forEach((group) => {
    try {
      userPermissions.push(...groupsToPermissions[group]);
    } catch {
      // ignore groups that we don't support mappings for
    }
  });
  return userPermissions;
};

export const isUserAllowed = (userGroups = [], group = 'Users') => getUserPermissions(userGroups).includes(group);

export const isUserReadOnly = (userGroups = [], groups) => userGroups.length === 2 && groups.every((reqGroup) => getUserPermissions(userGroups).includes(reqGroup));
