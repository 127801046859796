/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Input, Loading } from '@nike/frame-component-library';
import { Download } from '@nike/nike-design-system-icons';
import countries from 'i18n-iso-countries';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';

import './Overlay.css';

import { loadState } from '../../../utils/local-storage';
import {
  scsDefinitionGet,
  scsReportGet,
  scsHistoryGet,
} from '../../../utils/service-calls/scs';
import { getStores } from '../../../utils/service-calls/sls';
import { scsPermissions } from '../../../utils/tab-permissions';
import { PageTemplate, TableRowClick, ButtonBlack } from '../../reusable';
import { fetchFilteredStores, getFilterOptions } from '../SCSFilter';

import Overlay from './Overlay';

const config = require('../../../config')();

const requiredRed = '#d43f21';

const columns = [
  {
    accessor: (data) => data.storeNumber,
    Header: 'Store #',
    id: 'Store #',
  },
  {
    accessor: (data) => data.storeName,
    Header: 'Store Name',
    id: 'Store Name',
  },
  {
    accessor: (data) => data.country,
    Header: 'Country',
    id: 'Country',
  },
  {
    accessor: (data) => data.storeConfig,
    Header: 'Value',
    id: 'Store Config Value',
  },
  {
    accessor: (data) => data.scopeType,
    Header: 'Scope',
    id: 'Store Scope',
  },
  {
    accessor: (data) => data.lastModifiedOn,
    Header: 'Last Modified On',
    id: 'Last Modified On',
  },
  {
    accessor: (data) => data.lastModifiedBy,
    Header: 'Last Modified By',
    id: 'Last Modified By',
  },
];

const fields = [
  'id',
  'storeNumber',
  'name',
  'company',
  'facilityType',
  'businessConcept',
  'storeConcept',
  'address.country',
  'address.city',
  'address.state',
  'region',
  'offerings.name',
  'storeServices.serviceGroup',
  'storeServices.serviceSubGroup',
  'storeStatus',
];

const ConfigByKey = ({ location }) => {
  const [fetching, setFetching] = useState(false);
  const [scsKeyOptionsForRegion, setScsKeyOptionsForRegion] = useState([]);
  const [scsRegion, setScsRegion] = useState(null);
  const [search, setSearch] = useState('');
  const [selectedScsKeyConfigs, setSelectedScsKeyConfigs] = useState([]);
  const [selectedScsKeyOption, setSelectedScsKeyOption] = useState(null);
  const [selectedScsRegionOption, setSelectedScsRegionOption] = useState(null);
  const [stores, setStores] = useState([]);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState(null);
  const [isDefined, setIsDefined] = useState('');
  const [storeKeyData, setStoreKeyData] = useState(new Map());
  const [inputValue, setInputValue] = useState('');

  const { accessToken } = loadState('auth');

  const initialRegion = localStorage.getItem('ros-stores-region') === 'All Regions' ? '' : localStorage.getItem('ros-stores-region');

  const [filteringCondition, setFilteringCondition] = useState({
    configDefined: { label: 'All Configs', value: '' },
    country: { label: 'All Countries', value: '' },
    facilityType: { label: 'All Facility Types', value: '' },
    offering: { label: 'All Offerings', value: '' },
    region: initialRegion
      ? {
        label: initialRegion,
        value:
      initialRegion === 'All Regions'
        ? ''
        : initialRegion,
      }
      : { label: 'Select a Region', value: '' },
    regionSelected: false,
    search: '',
    serviceStatus: { label: 'All Service Status', value: '' },
    state: { label: 'All States', value: '' },
    storeService: { label: 'All Store Services', value: '' },
    storeStatus: { label: 'All Store Statuses', value: '' },
  });

const updateFilter = (filterType, value) => {
  setFilteringCondition({ ...filteringCondition, [filterType]: { label: value.label, value: value.value } });
};

  useEffect(() => {
    async function fetchData() {
      setFetching(true);
      const fieldQuery = fields.join(',');
      try {
        const response = await getStores(accessToken, fieldQuery, '');
        setFetching(false);
        setStores(response);
      } catch (err) {
        setFetching(false);
      }
    }
    fetchData();
  }, [accessToken]);

  const fetchScsKeyDataForStores = async (scsKey, storeList) => {
    setFetching(true);
    // create storeKeyDataMap for easy fetching
    let storeKeyDataMap;
    try {
      const response = await scsReportGet(accessToken, scsKey, scsRegion);
      storeKeyDataMap = new Map(
        response.body.flatMap((item) => item.stores.map((store) => [
            store,
            {
 lastModifiedBy: item.lastModifiedBy, lastModifiedOn: item.lastModifiedOn, scopeType: item.scopeType, value: String(item.value)
},
          ]))
      );
    } catch (err) {
      storeKeyDataMap = new Map();
    }
    setStoreKeyData(storeKeyDataMap);
    const configs = storeList
      .sort((a, b) => (/[a-z]/i.test(a.storeNumber) || /[a-z]/i.test(b.storeNumber)
          ? a.storeNumber > b.storeNumber
            ? 1
            : -1
          : Number(a.storeNumber) > Number(b.storeNumber)
          ? 1
          : -1))
      .map((store) => {
        const configFromMap = storeKeyDataMap.get(
          `${store.address.country}-${store.storeNumber}`
        );
        return {
          country: store.address.country,
          lastModifiedBy: configFromMap?.lastModifiedBy || '--',
          lastModifiedOn: configFromMap?.lastModifiedOn || '--',
          scopeType: configFromMap?.scopeType || 'Undefined',
          storeConfig: configFromMap?.value || 'Undefined',
          storeName: store.name,
          storeNumber: store.storeNumber,
        };
      });
    setFetching(false);
    setSelectedScsKeyConfigs(configs);
  };

  const fetchFilteredScsKeyDataForStores = async (storeList) => {
    setFetching(true);
    const configs = storeList
      .sort((a, b) => (/[a-z]/i.test(a.storeNumber) || /[a-z]/i.test(b.storeNumber)
          ? a.storeNumber > b.storeNumber
            ? 1
            : -1
          : Number(a.storeNumber) > Number(b.storeNumber)
          ? 1
          : -1))
      .map((store) => {
        const configFromMap = storeKeyData.get(
          `${store.address.country}-${store.storeNumber}`
        );
        return {
          country: store.address.country,
          lastModifiedBy: configFromMap?.lastModifiedBy || '--',
          lastModifiedOn: configFromMap?.lastModifiedOn || '--',
          scopeType: configFromMap?.scopeType || 'Undefined',
          storeConfig: configFromMap?.value || 'Undefined',
          storeName: store.name,
          storeNumber: store.storeNumber,
        };
      });
    setFetching(false);
    setSelectedScsKeyConfigs(configs);
  };

  useEffect(() => {
    fetchFilteredScsKeyDataForStores(fetchFilteredStores(filteringCondition, stores));
  }, [filteringCondition, storeKeyData]);

  const fetchSCSKeys = async (region) => {
    setFetching(true);
    const response = await scsDefinitionGet(accessToken, region);
    const { objects: definitions } = response?.body;
    const keyOptions = definitions
      .sort((a, b) => String(a.key).trim().localeCompare(String(b.key).trim()))
      .map((item) => ({ label: item.key, value: item.key }));
    setFetching(false);
    setScsKeyOptionsForRegion(keyOptions);
  };

  const updateRegion = (regionOption) => {
    const selectedScsRegion = regionOption.value;
    fetchSCSKeys(selectedScsRegion);
    setScsKeyOptionsForRegion([]);
    setScsRegion(selectedScsRegion);
    setSearch('');
    setSelectedScsKeyConfigs([]);
    setSelectedScsKeyOption(null);
    setSelectedScsRegionOption(regionOption);
  };

  const clearFilters = () => setFilteringCondition({
    configDefined: { label: 'All Configs', value: '' },
    country: { label: 'All Countries', value: '' },
    facilityType: { label: 'All Facility Types', value: '' },
    offering: { label: 'All Offerings', value: '' },
    region: initialRegion
      ? {
        label: initialRegion,
        value:
      initialRegion === 'All Regions'
        ? ''
        : initialRegion,
      }
      : { label: 'Select a Region', value: '' },
    regionSelected: false,
    search: '',
    serviceStatus: { label: 'All Service Status', value: '' },
    state: { label: 'All States', value: '' },
    storeService: { label: 'All Store Services', value: '' },
    storeStatus: { label: 'All Store Statuses', value: '' },
  });

  const updateScsKey = (keyOption) => {
    const scsKey = keyOption.value;
    fetchScsKeyDataForStores(scsKey, stores);
    setSearch('');
    setSelectedScsKeyOption(keyOption);
    clearFilters();
  };

  const getSearchedConfigs = (searchString, keyConfigs) => keyConfigs.filter((keyConfig) => {
      if (searchString !== '') {
        const regExp = new RegExp(searchString.trim(), 'i');
        return Object.keys(keyConfig).reduce(
          (acc, key) => acc || regExp.test(String(keyConfig[key])),
          false
        );
      } else {
        return true;
      }
    });

  const handleRowClick = async (row, key) => {
    setSelectedRowData(row);
    setLoading(true);
    setOverlayVisible(true);
    try {
      const storeId = `${row.country}-${row.storeNumber}`;
      console.log(storeId);
      const response = await scsHistoryGet(
        accessToken,
        storeId,
        key,
        scsRegion
      );
      setHistoryData(response.body);
      // setLoading(false);
      // setOverlayVisible(true);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const closeOverlay = () => {
    setOverlayVisible(false);
    setSelectedRowData(null);
  };

  const csvConfigFields = [
    'key',
    'storeNumber',
    'storeName',
    'country',
    'storeConfig',
    'scopeType',
    'lastModifiedOn',
    'lastModifiedBy',
  ];

  const invalidField = ' ';

  const missingValueCheck = (pathCheck, invField = invalidField) => (pathCheck ? JSON.stringify(pathCheck.replace(/,/g, ' ')) : invField);

  const scsConfigSchema = (config1) => [
    missingValueCheck(selectedScsKeyOption?.value),
    missingValueCheck(config1?.storeNumber),
    missingValueCheck(config1?.storeName),
    missingValueCheck(config1?.country),
    missingValueCheck(config1?.storeConfig),
    missingValueCheck(config1?.scopeType),
    missingValueCheck(config1?.lastModifiedOn),
    missingValueCheck(config1?.lastModifiedBy),
    '\r',
  ];

  const transformConfigData = (conf) => {
    let csvContent = '';
    conf.forEach((config1) => {
      csvContent = csvContent.concat(scsConfigSchema(config1));
    });
    return csvContent;
  };

  const handleDownload = async () => {
    const csvContent = transformConfigData(selectedScsKeyConfigs);
    const csv = document.createElement('a');
    const encodedUri = encodeURI(csvContent);
    csv.setAttribute('href', encodedUri);
    csv.href = URL.createObjectURL(
      new Blob([`${csvConfigFields}\n${csvContent}\n`], {
        type: 'text/csv;charset=utf-8',
      })
    );
    csv.setAttribute('download', `ConfigList.csv`);
    document.body.appendChild(csv);
    csv.click();
    document.body.removeChild(csv);
  };

  const filterDefinedUndefinedValues = (scsSelectedKeyConfigs) => {
    if (isDefined === '') {
      return scsSelectedKeyConfigs;
    } else if (isDefined === 'true') {
      return scsSelectedKeyConfigs.filter((conf) => conf.storeConfig !== 'Undefined');
    }
    return scsSelectedKeyConfigs.filter((conf) => conf.storeConfig === 'Undefined');
  };

  const getFilteredSearchedConfigs = (searchString, keyConfigs) => {
    const filteredConfigs = filterDefinedUndefinedValues(keyConfigs);
    return getSearchedConfigs(searchString, filteredConfigs);
  };

  const handleConfigDefinedDropdown = (filterType, value) => {
    setFilteringCondition({ ...filteringCondition, [filterType]: { label: value.label, value: value.value } });
    setIsDefined(value.value);
    // if isDefined is true, show defined values from scsSelectedKeyConfigs
    // if isDefined is false, show undefined values from scsSelectedKeyConfigs
    getFilteredSearchedConfigs(
      search,
      selectedScsKeyConfigs
    );
  };

  const filters = [
    {
      accessor: 'region',
      changeValue: (value) => {
        updateFilter('region', value);
        localStorage.setItem('ros-stores-region', value.label);
      },
      getLabel: (code) => code,
      id: 'regionFilter',
      isDisabled: false,
      label: 'All Regions',
      option: filteringCondition.region,
      // required: !this.state.regionSelected && !this.props.initialRegion,
    },
    {
      accessor: 'address.country',
      changeValue: (value) => updateFilter('country', value),
      // TODO: remove Türkiye hardcoding when dependent module releases a version with the new name
      getLabel: (code) => (code === 'TUR' ? 'Türkiye' : countries.getName(code, 'en')),
      id: 'countryFilter',
      isDisabled: stores.length === 0,
      label: 'All Countries',
      option: filteringCondition.country,
    },
    {
      accessor: 'address.state',
      changeValue: (value) => updateFilter('state', value),
      getLabel: (code) => code,
      id: 'stateFilter',
      isDisabled: stores.length === 0,
      label: 'All States',
      option: filteringCondition.state,
    },
    {
      accessor: 'facilityType',
      changeValue: (value) => updateFilter('facilityType', value),
      getLabel: (code) => code,
      id: 'facilityTypeFilter',
      isDisabled: stores.length === 0,
      label: 'All Facility Types',
      option: filteringCondition.facilityType,
    },
    // {
    //   accessor: (store) => (store.offerings ? store.offerings.map((offer) => offer.name) : []),
    //   changeValue: (value) => updateFilter('offering', value),
    //   getLabel: (code) => code,
    //   id: 'offeringFilter',
    //   isDisabled: stores.length === 0,
    //   label: 'All Offerings',
    //   option: filteringCondition.offering,
    // },
    // {
    //   accessor: (store) => (store.storeServices ? store.storeServices.map((service) => service.serviceGroup) : []),
    //   changeValue: (value) => updateFilter('storeService', value),
    //   getLabel: (code) => code,
    //   id: 'storeServiceFilter',
    //   isDisabled: stores.length === 0,
    //   label: 'All Services',
    //   option: filteringCondition.storeService,
    // },
    {
      accessor: 'serviceStatus',
      changeValue: (value) => updateFilter('serviceStatus', value),
      disabled: filteringCondition.storeService.value === '',
      getLabel: (code) => code,
      id: 'serviceStatusFilter',
      label: 'All Service Status',
      option: filteringCondition.serviceStatus,
    },
    {
      accessor: 'storeStatus',
      changeValue: (value) => updateFilter('storeStatus', value),
      getLabel: (code) => code,
      id: 'storeStatusFilter',
      isDisabled: stores.length === 0,
      label: 'All Store Statuses',
      option: filteringCondition.storeStatus,
    },
    {
      accessor: 'configDefined',
      changeValue: (value) => handleConfigDefinedDropdown('configDefined', value),
      getLabel: (code) => code,
      id: 'configDefinedFilter',
      isDisabled: stores.length === 0,
      label: 'All Configs',
      option: filteringCondition.configDefined,
    },
  ];

  const handleMenuOpen = () => {
    setInputValue(''); // Clear the input value when the menu is opened
  };

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const filterScsKeyOptionsForRegion = inputValue.length >= 3
  ? scsKeyOptionsForRegion.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
  : [];

  return (
    <PageTemplate
      auth={scsPermissions}
      description="View key configuration by store."
      help={(
        <a
          className="ncss-cta-primary-dark underline text-color-secondary"
          href="https://confluence.nike.com/display/RCFITC/RCF+KB+-+SCS+UI"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here to view the KB
        </a>
      )}
      page={(
        <main className="ncss-col-sm-12">
          {stores.length === 0 ? (
            <Loading />
          ) : (
            stores.length > 0 && (
              <article className="ncss-col-sm-10">
                <article className="ncss-col-sm-6 va-sm-t ta-sm-l">
                  AWS Region
                  <ReactSelect
                    classNamePrefix="react-select"
                    clearable={false}
                    id="scsRegion"
                    isDisabled={false}
                    options={config.scsRegionOptions}
                    styles={{
                      container: (styles) => ({ ...styles, zIndex: 100 }),
                      control: (styles) => ({
                        ...styles,
                        borderColor: 'lightgrey',
                        padding: '0px 0px 0px 3px',
                      }),
                    }}
                    value={selectedScsRegionOption}
                    onChange={(regionOption) => updateRegion(regionOption)}
                  />
                </article>
                {fetching && scsKeyOptionsForRegion.length === 0 ? (
                  <article className="ncss-col-sm-6 va-sm-t mt-6-sm">
                    <Loading />
                  </article>
                ) : (
                  scsKeyOptionsForRegion.length > 0 && (
                    <article className="ncss-col-sm-6 va-sm-t ta-sm-l">
                      Key
                      <ReactSelect
                        classNamePrefix="react-select"
                        id="scsKey"
                        inputValue={inputValue}
                        isDisabled={false}
                        noOptionsMessage={() => (inputValue.length < 3 ? 'Type at least 3 characters' : 'No options')}
                        options={filterScsKeyOptionsForRegion}
                        styles={{
                          container: (styles) => ({ ...styles, zIndex: 100 }),
                          control: (styles) => ({
                            ...styles,
                            borderColor: 'lightgrey',
                            padding: '0px 0px 0px 3px',
                          }),
                        }}
                        value={selectedScsKeyOption}
                        onChange={(keyOption) => updateScsKey(keyOption)}
                        onInputChange={handleInputChange}
                        onMenuOpen={handleMenuOpen} // Clear the input value on menu open
                      />
                    </article>
                  )
                )}
                {selectedScsKeyOption && (
                  <>
                    <section className="ncss-col-sm-12 va-sm-t ta-sm-l pb3-sm  pt3-sm ">
                      {filters.map((filter) => (
                        <article key={filter.id} className="ncss-col-sm-4 mt2-sm" style={{ display: filter.disabled ? 'none' : '' }}>
                          <ReactSelect
                            classNamePrefix="react-select"
                            id={filter.id}
                            isDisabled={filter.isDisabled}
                            options={getFilterOptions(fetchFilteredStores(filteringCondition, stores), filter)}
                            styles={{
              menu: (styles) => ({ ...styles, zIndex: 100 }),
              singleValue: (styles) => ({ ...styles, color: filter.required ? requiredRed : styles.color }),
            }}
                            value={filter.option}
                            onChange={(value) => filter.changeValue(value)}
                          />
                        </article>
      ))}
                    </section>
                    <section className="ncss-col-sm-12 ta-sm-l va-sm-t">
                      <div className="outer-container">
                        <section className="ncss-col-sm-6 ta-sm-l va-sm-t">
                          <Input
                            label="Search"
                            placeholder="Search..."
                            style={{ zIndex: 1 }}
                            type="text"
                            value={search}
                            onChange={({ target: { value } }) => setSearch(value)}
                          />
                        </section>
                        <div className="tooltip-custom"> <Download className="download-icon" onClick={() => handleDownload()} />
                          <span className="tooltiptext">Download Configs</span>
                        </div>
                      </div>
                    </section>
                  </>
                )}
                {fetching && selectedScsKeyOption
                  ? selectedScsKeyConfigs.length === 0 && <Loading />
                  : selectedScsKeyConfigs.length > 0 && (
                  <article className="ncss-col-sm-12 ta-sm-l va-sm-t">
                    <br />
                    <TableRowClick
                      showPagination
                      columns={columns}
                      data={getFilteredSearchedConfigs(
                            search,
                            selectedScsKeyConfigs
                          )}
                      defaultPageSize={20}
                          // on clicking row pass row data to fetchHistory function
                          // handleClick={(row, key) => fetchHistory(row, selectedScsKeyOption.value)}
                      handleClick={(row) => handleRowClick(row, selectedScsKeyOption.value)}
                    />
                  </article>
                    )}
              </article>
            )
          )}
          <div>
            <Overlay
              data={historyData}
              isVisible={isOverlayVisible}
              loading={loading}
              onClose={closeOverlay}
            />
          </div>
        </main>
      )}
      path={location.pathname}
      title="Store Config Service"
    />
  );
};

ConfigByKey.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
    .isRequired,
};

export default ConfigByKey;
