import { requestWrapper } from './reusable';

const config = require('../../config')();

// data: { keyType, body: { scope, scopeType, key, value, hierarchy (geo | country | brand) } }
export const scsDefinitionPost = (token, data, scsRegion) => requestWrapper(
  token,
  config.scsDefinitionResource,
  'post',
  { scsRegion },
  { key: data.body.key, type: data.keyType },
);

// body: { scope, scopeType, key, value, hierarchy (geo | country | brand) }
export const scsPost = (token, body, scsRegion) => requestWrapper(
  token,
  config.scsResource,
  'post',
  { scsRegion },
  body,
);

export const scsGet = (token, storeIdentifier, scsRegion) => requestWrapper(
  token,
  config.scsResource,
  'get',
  { scsRegion, storeIdentifier },
);

export const scsDefinitionGet = (token, scsRegion) => requestWrapper(
  token,
  config.scsDefinitionResource,
  'get',
  { scsRegion },
);

export const scsReportGet = (token, scsKey, scsRegion) => requestWrapper(
  token,
  config.scsReport,
  'get',
  { scsKey, scsRegion },
);

export const scsHistoryGet = (token, scsStore, scsKey, scsRegion) => requestWrapper(
  token,
  config.scsHistory,
  'get',
  { scsKey, scsRegion, scsStore },
);
