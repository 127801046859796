const { Maybe } = require('../types/maybe');

const and = (p1, p2) => (o) => p1(o) && p2(o);

const or = (p1, p2) => (o) => p1(o) || p2(o);

const not = (predicate) => (o) => !predicate(o);

const isAlwaysTrue = () => true;

const isAlwaysFalse = () => false;

const anyOf = (...predicates) => predicates.reduce((acc, predicate) => or(acc, predicate), isAlwaysFalse);

const allOf = (...predicates) => predicates.reduce((acc, predicate) => and(acc, predicate), isAlwaysTrue);

const isSome = (o) => Maybe.of(o).isSome();

const isNone = not(isSome);

module.exports = {
  isSome, isNone, not, isAlwaysTrue, isAlwaysFalse, and, or, anyOf, allOf
};